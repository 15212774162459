import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Gap } from 'components/shared/Gap';
import { HeaderDetailsItem } from 'components/shared/HeaderDetailsItem';
import { ReactComponent as AccountIdIcon } from 'assets/icons/custodial-accounts/account-id.svg';
import { ITokenDetailsPageHeader } from 'ui-interfaces/token-details/i-token-details-page-header';

type Props = {
  data: ITokenDetailsPageHeader;
};

/** @deprecated */
export const TokenDetailsPageHeader: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h3" color="grey.700">
        {data.title}
      </Typography>
      <Gap size="_20px" />
      <HeaderDetailsItem
        value={data.itemValue}
        label={t('nft.nftDetails.headerIdLabel')}
        icon={<AccountIdIcon />}
        large
      />
    </>
  );
};
