import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomizedGrid } from 'components/shared/grids/CustomizedGrid';
import { Gap } from 'components/shared/Gap';
import {
  CommonLayoutContainer,
  CommonLayoutHeader,
  CommonPageTitle,
  CommonLayoutContent,
  CommonGridBox,
} from 'components/shared/layouts/CommonLayout';
import { GridPropsDynamic } from 'ui-interfaces/grid-props-dynamic';
import { WalletRow } from 'ui-interfaces/row-interfaces/wallets-deprecated';
import { columnsWalletsWithOwner } from './columns-definitions-with-owner';
import { columnsWallets } from './columns-definitions';
import { walletsFilters, walletsSelectionMacroCommand } from './wallets-filter-config';

interface Props {
  walletsGridProps: GridPropsDynamic<WalletRow>;
  isOwnerShown: boolean;
}

/** @deprecated */
const WalletsPage: React.FC<Props> = ({ walletsGridProps, isOwnerShown }) => {
  const { t } = useTranslation();
  return (
    <CommonLayoutContainer>
      <CommonLayoutHeader>
        <Gap size="_40px" />
        <CommonPageTitle>{t('walletsPage.title')}</CommonPageTitle>
        <Gap size="_32px" />
      </CommonLayoutHeader>
      <CommonLayoutContent>
        <CommonGridBox>
          <CustomizedGrid
            columns={isOwnerShown ? columnsWalletsWithOwner : columnsWallets}
            headerName={t('walletsPage.gridTitle')}
            filters={walletsFilters}
            selectionMacroCommand={walletsSelectionMacroCommand}
            noResultsTitle={t('walletsPage.noResultsTitle')}
            noResultsSubtitle={t('walletsPage.noResultsSubtitle')}
            containerHeight="100%"
            {...walletsGridProps}
          />
        </CommonGridBox>
      </CommonLayoutContent>
    </CommonLayoutContainer>
  );
};

export default WalletsPage;
