import React from 'react';
import { ITokenDetailsPage } from 'ui-interfaces/token-details/i-token-details-page';
import { BreadCrumbsItem } from 'components/shared/Breadcrumbs';
import { Loading } from './layout/Loading';
import { Error } from './layout/Error';
import { Content } from './layout/Content';

type Props = {
  breadCrumbs: BreadCrumbsItem[];
  isLoading: boolean;
  isError: boolean;
  tokenDetailsPage?: ITokenDetailsPage;
};

/** @deprecated */
export const TokenDetailsPageContent: React.FC<Props> = ({ breadCrumbs, isLoading, isError, tokenDetailsPage }) => {
  if (isLoading) {
    return <Loading breadCrumbs={breadCrumbs} />;
  }

  if (isError) {
    return <Error breadCrumbs={breadCrumbs} />;
  }

  return <Content breadCrumbs={breadCrumbs} tokenDetailsPage={tokenDetailsPage} />;
};
