import React from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import i18n from 'localizations';
import { GridCellExpand } from 'components/shared/Cells/GridCellExpand';
import { WalletAssetRow } from 'ui-interfaces/row-interfaces/wallets-deprecated/wallet-asset-row';
import { CryptoAssetsNameCell } from '../CryptoAssetsName';

/** @deprecated */
export const columns: GridColDef[] = [
  {
    field: 'assetType',
    headerName: i18n.t('walletDetailsPage.assets.cols.name'),
    renderCell: (params: GridRenderCellParams) => {
      const { cryptoAssetsNameCellProps } = params.row as WalletAssetRow;
      return <CryptoAssetsNameCell data={cryptoAssetsNameCellProps} />;
    },
    flex: 1.25,
    headerAlign: 'left',
    align: 'left',
  },
  {
    field: 'address',
    headerName: i18n.t('walletDetailsPage.assets.cols.address'),
    renderCell: (params: GridRenderCellParams) => (
      <GridCellExpand value={params.value} width={params.colDef.computedWidth} />
    ),
    flex: 2,
  },
  {
    field: 'blockchain',
    headerName: i18n.t('walletDetailsPage.assets.cols.blockchain'),
    flex: 1,
  },
  {
    field: 'balance',
    headerName: i18n.t('walletDetailsPage.assets.cols.balance'),
    renderCell: (params: GridRenderCellParams) => (
      <GridCellExpand value={params.value} width={params.colDef.computedWidth} />
    ),
    flex: 0.75,
    headerAlign: 'right',
    align: 'right',
  },
];
