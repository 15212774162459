import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useGetWalletDetailsByIdQuery } from 'redux/api/api-wallets';
import ContentBlock from 'components/wapi-deprecated/wallets/WalletDetailsPage';
import { WalletDetailsUnderIdentityDetailsUrlParams } from 'navigation/url-params-type';
import { WalletDetailsPageTabs } from 'ui-enums/tabs/wallets/wallet-details-page-tabs';
import { useTransformCollectionItemResponse } from 'hooks/use-transform-collection-item-response';
import { WalletDetailsResponseModel } from 'models/response/wallets-deprecated/wallet-details-response-model';
import { WalletDetailsHeaderViewModel } from 'view-models/wallets-deprecated/wallet-details-header-vm';
import { getUser } from 'redux/api/api-users';
import { useBcWalletDetailsPage } from 'hooks/breadcrumbs/use-bc-wallet-details-page';
import { checkIfAccessAllowed } from 'utils/sub-apis/check-if-access-allowed';
import { SubApiCases } from 'navigation/sub-api-cases';
import { createWalletDetailsHeaderViewModel } from 'factories/wallets-deprecated/create-wallet-details-header-view-model';

/** @deprecated */
export const WalletDetailsPage = () => {
  // Check for Allowed SubApis - start
  const { data: user } = useSelector(getUser.select(undefined));
  const [isOwnerShown, setIsOwnerShown] = useState(false);

  useEffect(() => {
    if (user?.organization?.allowedSubApis) {
      const shouldOwnerBeShown = checkIfAccessAllowed(
        [
          SubApiCases.trustCompliance,
          SubApiCases.trustWalletCompliance,
          SubApiCases.walletCompliance,
          SubApiCases.default,
        ],
        user?.organization?.allowedSubApis,
      );
      setIsOwnerShown(shouldOwnerBeShown);
    }
  }, [user]);
  // Check for Allowed SubApis - end

  const { walletId } = useParams<WalletDetailsUnderIdentityDetailsUrlParams>();

  const [isDetailsPageError, setIsDetailsPageError] = useState(false);
  const [tab, setTab] = useState(WalletDetailsPageTabs.crypto);

  const { breadCrumbs, pathMatch } = useBcWalletDetailsPage();

  // header - start
  const {
    data,
    isLoading: isHeaderDataLoading,
    isError: isHeaderDataError,
  } = useGetWalletDetailsByIdQuery(walletId!, {
    refetchOnMountOrArgChange: true,
  });

  const [formattedHeaderData] = useTransformCollectionItemResponse<
    WalletDetailsResponseModel,
    WalletDetailsHeaderViewModel
  >(data, createWalletDetailsHeaderViewModel);
  // header - end

  return (
    <ContentBlock
      setIsDetailsPageError={setIsDetailsPageError}
      breadCrumbs={breadCrumbs}
      walletId={walletId!}
      pathMatch={pathMatch}
      tab={tab}
      setTab={setTab}
      headerProps={formattedHeaderData}
      error={isHeaderDataError || isDetailsPageError}
      loading={isHeaderDataLoading}
      isOwnerShown={isOwnerShown}
    />
  );
};
