import React, { useState, useRef, useLayoutEffect } from 'react';
import { CommonTooltip } from 'components/shared/CommonTooltip';
import { isOverflown } from 'utils/helpers';
import { pxToRem } from 'utils/styling-utils';
import { BOX_SIZE } from '../constants';
import { StyledTitleTypography, BOX_MARGIN_RIGHT, CELL_INLINE_PADDING } from './styled';

type Props = {
  title: string;
  width: number;
};

/** @deprecated */
export const Title: React.FC<Props> = ({ title, width }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const titleRef = useRef(null);

  useLayoutEffect(() => {
    const isTitleCropped = isOverflown(titleRef.current!);
    setShowTooltip(isTitleCropped);
  }, []);

  return (
    <CommonTooltip
      title={title}
      placement="top"
      disableHoverListener={!showTooltip}
      disableFocusListener={!showTooltip}
      disableTouchListener={!showTooltip}
    >
      <StyledTitleTypography
        sx={{
          maxWidth: pxToRem(width - BOX_SIZE - BOX_MARGIN_RIGHT - CELL_INLINE_PADDING),
        }}
        ref={titleRef}
        variant="bodyDefaultMedium"
      >
        {title}
      </StyledTitleTypography>
    </CommonTooltip>
  );
};
