import React, { useCallback, useEffect, useState } from 'react';
import { PathMatch, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AutoHeightGrid } from 'components/shared/grids/AutoHeightGrid';
import { CommonAutoHeightGridBox } from 'components/shared/layouts/CommonLayout';
import { TokensByIdApiParams, useLazyGetWalletTokensByIdQuery } from 'redux/api/api-wallets';
import { useExport } from 'hooks/use-export';
import { TokenRow } from 'ui-interfaces/row-interfaces/wallets-deprecated/token-row';
import { FilterResult } from 'filters/interfaces/filter-result';

import { tokensFilters, tokensSelectionMacroCommand } from './tokens-filter-config';
import { onResponseModelTransform, onRowClick, onExportClick } from './handlers';
import { columns } from './columns-definitions';

type Props = {
  walletId: string;
  pathMatch: PathMatch<string> | null;
  setIsDetailsPageError: React.Dispatch<React.SetStateAction<boolean>>;
};

/** @deprecated */
export const TokensGrid: React.FC<Props> = ({ walletId, pathMatch, setIsDetailsPageError }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { downloadCsv, isLoading, error } = useExport();

  const [getTokensByIdTrigger, tokensByIdResult] = useLazyGetWalletTokensByIdQuery();

  const fetchTokensById = useCallback(
    (page?: number, filterResult?: FilterResult) =>
      getTokensByIdTrigger({ walletId, page, filterResult } as TokensByIdApiParams, false),
    [getTokensByIdTrigger, walletId],
  );

  const [rows, setRows] = useState<TokenRow[]>([]);
  const [resourceCount, setResourceCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(tokensByIdResult.isUninitialized);

  useEffect(() => {
    fetchTokensById();
  }, [fetchTokensById]);

  useEffect(() => {
    if (tokensByIdResult.isSuccess && tokensByIdResult.data) {
      const transformedItems = tokensByIdResult.data.data.map(onResponseModelTransform);
      setRows(transformedItems);
      setResourceCount(tokensByIdResult.data.meta?.resourceCount ?? 0);
      setLoading(tokensByIdResult.isFetching);
    }
  }, [tokensByIdResult.data, tokensByIdResult.isFetching, tokensByIdResult.isSuccess]);

  useEffect(() => {
    setIsDetailsPageError(Boolean(tokensByIdResult.error || error));
  }, [error, setIsDetailsPageError, tokensByIdResult.error]);

  return (
    <CommonAutoHeightGridBox>
      <AutoHeightGrid
        columns={columns}
        headerName={t('walletDetailsPage.tokens.title')}
        filters={tokensFilters}
        selectionMacroCommand={tokensSelectionMacroCommand}
        noResultsTitle={t('walletDetailsPage.tokens.noResultsTitle')}
        noResultsSubtitle={t('walletDetailsPage.tokens.noResultsSubtitle')}
        containerHeightWhenNonAHMode="515px"
        rows={rows}
        rowCount={resourceCount}
        isLoading={loading}
        error={tokensByIdResult.error || error}
        fetchOtherItems={fetchTokensById}
        onGridRowClick={onRowClick(navigate, walletId!, pathMatch)}
        onExportClick={onExportClick(downloadCsv, walletId!)}
        isExportLoading={isLoading}
      />
    </CommonAutoHeightGridBox>
  );
};
