import React from 'react';
import { IPropertiesRow } from 'ui-interfaces/token-details/rows/i-properties-row';
import { StyledRow } from './styled';
import { PropertyItem } from './PropertyItem';

type Props = {
  data: IPropertiesRow;
};

/** @deprecated */
export const PropertiesRow: React.FC<Props> = ({ data }) => {
  return (
    <StyledRow>
      {data.properties.map((p, index, arr) => {
        return (
          <PropertyItem key={p.label} label={p.label} value={p.value} isLast={arr.length - 1 === index} index={index} />
        );
      })}
    </StyledRow>
  );
};
