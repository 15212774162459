import i18n from 'localizations';
import { TokenDetailsNode } from 'ui-interfaces/token-details/token-details-node';
import { TokenDetailsRowType } from 'ui-enums/token-details/token-details-row-type';

/** @deprecated */
export const loadingNodes: TokenDetailsNode[] = [
  {
    id: 'image',
    noBottomBorder: true,
    items: [
      {
        id: 'imageLoading',
        rowType: TokenDetailsRowType.imageLoading,
      },
    ],
  },
  {
    id: '01',
    title: i18n.t('nft.nftDetails.detailsTitle'),
    items: [
      {
        id: '1',
        rowType: TokenDetailsRowType.loading,
      },
      {
        id: '2',
        rowType: TokenDetailsRowType.loading,
      },
      {
        id: '3',
        rowType: TokenDetailsRowType.loading,
      },
      {
        id: '4',
        rowType: TokenDetailsRowType.loading,
      },
      {
        id: '5',
        rowType: TokenDetailsRowType.loading,
      },
      {
        id: '6',
        rowType: TokenDetailsRowType.loading,
      },
      {
        id: '7',
        rowType: TokenDetailsRowType.loading,
      },
      {
        id: '8',
        rowType: TokenDetailsRowType.loading,
      },
      {
        id: '9',
        rowType: TokenDetailsRowType.loading,
      },
    ],
  },
];
