/** @deprecated */
export const formatWalletAddress = (walletAddress: string) => {
  /**
   * e.g.
   * tb1qazqgykh4k80temzzc0g7a5x2v9euk8gug7l5gs => tb1qaz...g7l5gs
   */
  const first = walletAddress.slice(0, 6);
  const second = walletAddress.slice(-6);
  return `${first}...${second}`;
};
