import i18n from 'localizations';
import { ITokenDetailsPage } from 'ui-interfaces/token-details/i-token-details-page';
import { TokenDetailsNode } from 'ui-interfaces/token-details/token-details-node';
import { ITokenDetailsPageHeader } from 'ui-interfaces/token-details/i-token-details-page-header';
import { TokenDetailResponseModel } from 'models/response/tokens/token-detail-response-model';
import { TokenDetailsRowType } from 'ui-enums/token-details/token-details-row-type';
import { IImageRow } from 'ui-interfaces/token-details/rows/i-image-row';
import { FileType } from 'ui-enums/file-type';
import { ILinkRow } from 'ui-interfaces/token-details/rows/i-link-row';
import { IBlockchainRow } from 'ui-interfaces/token-details/rows/i-blockchain-row';
import { IPlainRow } from 'ui-interfaces/token-details/rows/i-plain-row';
import { getTokenTypeLabel } from 'utils/labels-mapping/get-token-type-label';
import { IFileTypeRow } from 'ui-interfaces/token-details/rows/i-file-type-row';
import { IPropertiesRow } from 'ui-interfaces/token-details/rows/i-properties-row';
import { Valued } from 'ui-interfaces/shared/valued';
import { formatDate } from 'utils/format-date';
import { getFileTypeLabel } from 'utils/wallets-deprecated/token-details/get-file-type-label';

/** @deprecated */
export class TokenDetailsPageViewModel implements ITokenDetailsPage {
  readonly pageHeader: ITokenDetailsPageHeader = {
    title: '',
    itemValue: '',
  };

  readonly sectionHeader: string;

  readonly nodes: TokenDetailsNode[] = [];

  constructor(token: TokenDetailResponseModel) {
    this.pageHeader.title = token.name ?? '';
    this.pageHeader.itemValue = token.id;

    this.sectionHeader = i18n.t('nft.nftDetails.pageTitle');

    this.nodes = [
      {
        id: 'imageSection',
        noBottomBorder: true,
        items: [
          {
            id: 'tokenImage',
            tokenName: token.name ?? '',
            rowType: TokenDetailsRowType.image,
            collection: token.collectionName,
            creator: token.creatorName,
            description: token.description,
            imageUrl: token.mdThumbnail,
            mediaType: token.fileType as FileType,
          } as IImageRow,
        ],
      },
      {
        id: 'details',
        title: i18n.t('nft.nftDetails.detailsTitle'),
        items: [
          {
            id: 'contractAddress',
            rowType: TokenDetailsRowType.link,
            label: i18n.t('nft.nftDetails.rowLabels.contractAddress'),
            value: token.contractAddress,
            url: token.contractAddressUrl,
          } as ILinkRow,
          {
            id: 'blockchain',
            rowType: TokenDetailsRowType.blockchain,
            label: i18n.t('nft.nftDetails.rowLabels.blockchain'),
            network: token.network,
          } as IBlockchainRow,
          {
            id: 'tokenId',
            rowType: TokenDetailsRowType.plain,
            label: i18n.t('nft.nftDetails.rowLabels.tokenId'),
            value: token.blockchainTokenId ?? '',
          } as IPlainRow,
          {
            id: 'tokenStandard',
            rowType: TokenDetailsRowType.plain,
            label: i18n.t('nft.nftDetails.rowLabels.tokenStandart'),
            value: getTokenTypeLabel(token.tokenType),
          } as IPlainRow,
          {
            id: 'fileType',
            rowType: TokenDetailsRowType.fileType,
            label: i18n.t('nft.nftDetails.rowLabels.fileType'),
            value: getFileTypeLabel(token.fileType as FileType),
            mediaType: token.fileType as FileType,
          } as IFileTypeRow,
          {
            id: 'amount',
            rowType: TokenDetailsRowType.plain,
            label: i18n.t('nft.nftDetails.rowLabels.amount'),
            value: token.amount ?? '',
          } as IPlainRow,
          {
            id: 'totalEdition',
            rowType: TokenDetailsRowType.plain,
            label: i18n.t('nft.nftDetails.rowLabels.totalEdition'),
            value: token.totalEditions ? `${token.totalEditions}` : '',
          } as IPlainRow,
          {
            id: 'dateCreated',
            rowType: TokenDetailsRowType.plain,
            label: i18n.t('nft.nftDetails.rowLabels.dateCreated'),
            value: formatDate(token.createdAt),
          } as IPlainRow,
          {
            id: 'properties',
            rowType: TokenDetailsRowType.properties,
            properties: token.additionalProperties ?? [],
          } as IPropertiesRow,
        ]
          .filter(i => {
            if ('value' in i) {
              return !!(i as Valued).value;
            }

            return true;
          })
          .filter(i => {
            if (i.rowType === TokenDetailsRowType.properties) {
              return (i as IPropertiesRow).properties.length > 0;
            }

            return true;
          }),
      },
    ];
  }
}
