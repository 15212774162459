import { WalletAssetResponseModel } from 'models/response/wallets-deprecated/wallet-asset-response-model';
import { createWalletAssetViewModel } from 'factories/wallets-deprecated/create-wallet-asset-view-model';
import { DownloadCSV } from 'hooks/use-export/interfaces';
import { FilterResult } from 'filters/interfaces/filter-result';
import { API_ROUTES } from 'redux/api/api-routes';
import { getRouteWithParams } from 'utils/get-route-with-params';

/** @deprecated */
export const onResponseModelTransform = (walletAsset: WalletAssetResponseModel) => {
  return createWalletAssetViewModel(walletAsset);
};

/** @deprecated */
export const onExportClick = (downloadCsv: DownloadCSV, walletId: string) => (filterResult?: FilterResult) => {
  downloadCsv({ urlSegment: getRouteWithParams(API_ROUTES.private.WALLET_ASSETS, { walletId }), filterResult });
};
