import React from 'react';
import { LeftCell } from 'components/shared/detail-page/rows/shared/LeftCell';
import { RowBox } from 'components/shared/detail-page/rows/shared/RowBox';
import { IFileTypeRow } from 'ui-interfaces/token-details/rows/i-file-type-row';
import { RightCell } from './RightCell';

type Props = {
  data: IFileTypeRow;
  hasBottomBorder?: boolean;
};

/** @deprecated */
export const FileTypeRow: React.FC<Props> = ({ data, hasBottomBorder = true }) => {
  return (
    <RowBox hasBottomBorder={hasBottomBorder}>
      <LeftCell value={data.label} />
      <RightCell mediaType={data.mediaType} value={data.value} />
    </RowBox>
  );
};
