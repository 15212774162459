import i18n from 'localizations';
import { SingleValueFilter } from 'filters/single-value-filter';
import { MultiValueFilter } from 'filters/multi-value-filter';
import { Filter } from 'filters/interfaces/filter';
import { Command } from 'filters/interfaces/command';
import { FilterCommand } from 'filters/commands/filter-command';
import { SelectionMacroCommand } from 'filters/commands/selection-macro-command';
import { Operators } from 'filters/operators';
import { NetworkChain } from 'ui-enums/response/network-chain';

const nameFilter = new SingleValueFilter({
  property: 'assetName',
  displayProperty: i18n.t('walletDetailsPage.assets.cols.name'),
  operators: [Operators.contains],
  operatorsDisplayValues: [i18n.t('filters.operators.contains')],
  initialOperator: Operators.contains,
  initialValue: '',
});

const addressFilter = new SingleValueFilter({
  property: 'address',
  displayProperty: i18n.t('walletDetailsPage.assets.cols.address'),
  operators: [Operators.contains, Operators.equals],
  operatorsDisplayValues: [i18n.t('filters.operators.contains'), i18n.t('filters.operators.equals')],
  initialOperator: Operators.contains,
  initialValue: '',
});

const blockchainFilter = new MultiValueFilter({
  property: 'network',
  displayProperty: i18n.t('walletDetailsPage.assets.cols.blockchain'),
  operators: [Operators.in],
  operatorsDisplayValues: [i18n.t('filters.operators.equals')],
  initialOperator: Operators.in,
  values: [
    `${NetworkChain.bitcoinMainnet},${NetworkChain.bitcoinTestnet}`,
    `${NetworkChain.mainnet},${NetworkChain.goerli}`,
    `${NetworkChain.polygonMainnet},${NetworkChain.polygonMumbai}`,
    `${NetworkChain.solanaMainnet},${NetworkChain.solanaDevnet},${NetworkChain.solanaTestnet}`,
    `${NetworkChain.cardanoMainnet},${NetworkChain.cardanoTestnet}`,
    `${NetworkChain.polkadotMainnet},${NetworkChain.polkadotWestend}`,
    `${NetworkChain.bnbSmartChainMainnet},${NetworkChain.bnbSmartChainTestnet}`,
    `${NetworkChain.avalancheMainnet},${NetworkChain.avalancheFuji}`,
  ],
  displayValues: [
    i18n.t('cryptoAssets.btc.long'),
    i18n.t('cryptoAssets.eth.long'),
    i18n.t('cryptoAssets.matic.long'),
    i18n.t('cryptoAssets.sol.long'),
    i18n.t('cryptoAssets.ada.long'),
    i18n.t('cryptoAssets.dot.long'),
    i18n.t('networkChain.bnb.long'),
    i18n.t('cryptoAssets.avax.long'),
  ],
  initialValue: '',
});

const balanceFilter = new SingleValueFilter({
  property: 'balanceNumber',
  displayProperty: i18n.t('walletDetailsPage.assets.cols.balance'),
  operators: [Operators.contains, Operators.equals, Operators.greaterThan, Operators.lessThan],
  operatorsDisplayValues: [
    i18n.t('filters.operators.contains'),
    i18n.t('filters.operators.equals'),
    i18n.t('filters.operators.greaterThan'),
    i18n.t('filters.operators.lessThan'),
  ],
  initialOperator: Operators.contains,
  initialValue: '',
});

/** @deprecated */
export const filters: Filter[] = [nameFilter, addressFilter, blockchainFilter, balanceFilter];

const commands: Command[] = [
  new FilterCommand(nameFilter),
  new FilterCommand(addressFilter),
  new FilterCommand(blockchainFilter),
  new FilterCommand(balanceFilter),
];

/** @deprecated */
export const selectionMacroCommand = new SelectionMacroCommand(commands, 0); // slot is an index of nameFilter
