import i18n from 'localizations';
import { WalletResponseModel } from 'models/response/wallets-deprecated/wallet-response-model';
import { WalletRow } from 'ui-interfaces/row-interfaces/wallets-deprecated';
import { formatDate } from 'utils/format-date';
import { IWalletMultiAssetsCell } from 'ui-interfaces/wallets-deprecated/i-wallet-multi-assets-cell';
import { AssetByNetworkIconSize } from 'ui-enums/asset-by-network-icon-size';
import { mapAssetByNetworkIconSizeToNumber } from 'utils/multi-mapping/asset-by-network-icon-size/map-asset-by-network-icon-size-to-number';
import { generateRandomUUID } from 'utils/generate-random-uuid';
import { getDefaultAssetIconForWalletsList } from 'utils/wallets-deprecated/list/get-default-asset-icon-for-wallets-list';
import { getAssetLongNameForWalletsList } from 'utils/wallets-deprecated/list/get-asset-long-name-for-wallets-list';

/** @deprecated */
export class WalletViewModel implements WalletRow {
  readonly id: string;

  readonly walletName: string;

  readonly walletId: string;

  readonly owner: string;

  readonly ownerTooltipMessage: string;

  readonly dateCreated: string;

  readonly walletMultiAssetsCellProps: IWalletMultiAssetsCell;

  private static readonly iconSize = AssetByNetworkIconSize['34px'];

  constructor(wallet: WalletResponseModel) {
    this.id = wallet.id;
    this.walletName = wallet.name;
    this.walletId = wallet.id;
    this.owner = wallet.ownerIdentityDisplayName ?? i18n.t('walletsPage.noData');
    this.ownerTooltipMessage = wallet.ownerIdentityDisplayName ? '' : i18n.t('walletsPage.noOwnerTooltipMessage');
    this.dateCreated = formatDate(wallet.createdAt);
    this.walletMultiAssetsCellProps = {
      assets: wallet.assets.map(a => ({
        id: generateRandomUUID(),
        imageUrl: a.xsAssetIcon,
        shortName: a.assetTicker ? a.assetTicker.toUpperCase() : i18n.t('walletsPage.defaultAssetTicker'),
        longName: getAssetLongNameForWalletsList(a.assetName),
        defaultAssetIcon: getDefaultAssetIconForWalletsList(a.assetName, WalletViewModel.iconSize),
      })),
      size: mapAssetByNetworkIconSizeToNumber(WalletViewModel.iconSize),
    };
  }
}
