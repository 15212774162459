import { getAssetByNetworkIcon } from 'utils/icons-mapping/get-asset-by-network-icon';
import { AssetByNetwork } from 'ui-enums/response/asset-by-network';
import { mapAssetByNetworkIconSizeToDefaultAssetIcon } from 'utils/icons-mapping/mapAssetByNetworkIconSizeToDefaultAssetIcon';
import { AssetByNetworkIconSize } from 'ui-enums/asset-by-network-icon-size';

/** @deprecated */
export const getDefaultAssetIconForWalletsList = (
  assetName: string | undefined,
  size: AssetByNetworkIconSize,
): JSX.Element => {
  return assetName === AssetByNetwork.nft
    ? getAssetByNetworkIcon(AssetByNetwork.nft, size)!
    : mapAssetByNetworkIconSizeToDefaultAssetIcon(size);
};
