import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Breadcrumbs, { BreadCrumbsItem } from 'components/shared/Breadcrumbs';
import { CommonLayoutContainer, CommonLayoutContent, CommonLayoutHeader } from 'components/shared/layouts/CommonLayout';
import { Gap } from 'components/shared/Gap';
import { HeaderDetailsItem } from 'components/shared/HeaderDetailsItem';
import { HeaderBox } from 'components/shared/detail-page/HeaderBox';
import { ShadowBox } from 'components/shared/detail-page/rows/shared/ShadowBox';
import { ReactComponent as AccountIdIcon } from 'assets/icons/custodial-accounts/account-id.svg';
import { StyledSkeleton } from './styled';
import { DetailNode } from '../DetailNode';
import { loadingNodes } from './loading-nodes';

type Props = {
  breadCrumbs: BreadCrumbsItem[];
};

/** @deprecated */
export const Loading: React.FC<Props> = ({ breadCrumbs }) => {
  const { t } = useTranslation();

  return (
    <CommonLayoutContainer>
      <CommonLayoutHeader>
        <HeaderBox>
          <Breadcrumbs items={breadCrumbs} />
          <Gap size="_20px" />
          <StyledSkeleton variant="rectangular" />
          <Gap size="_20px" />
          <HeaderDetailsItem
            value=""
            label={t('nft.nftDetails.headerIdLabel')}
            icon={<AccountIdIcon />}
            loading
            large
          />
        </HeaderBox>
      </CommonLayoutHeader>
      <CommonLayoutContent>
        <Gap size="_24px" />
        <ShadowBox>
          <Typography variant="h6">{t('nft.nftDetails.pageTitle')}</Typography>
          <Gap size="_16px" />
          {loadingNodes.map((n, index, originalArray) => (
            <DetailNode key={n.id} node={n} hasBottomBorder={!n.noBottomBorder && index !== originalArray.length - 1} />
          ))}
        </ShadowBox>
        <Gap size="_48px" />
      </CommonLayoutContent>
    </CommonLayoutContainer>
  );
};
