import React from 'react';
import { Route } from 'react-router-dom';
import { CONTACT_US_HREF, PATHS, ROUTE_SEGMENTS } from 'navigation/constants';
import { SubApiCases } from 'navigation/sub-api-cases';
import { PermissionBasedAccessRestriction } from 'navigation/restrictions/PermissionBasedAccessRestriction';
import { CustodialAccountsPageRestriction } from 'navigation/restrictions/specific/CustodialAccountsPageRestriction';
import { Index } from 'components/global/Index';
// route guards - start
import { PrivateRoutesRestriction } from 'navigation/restrictions/PrivateRoutesRestriction';
import { PermissionRoutesRestriction } from 'navigation/restrictions/PermissionRoutesRestriction';
import { ErrorRouteRestriction } from 'navigation/restrictions/ErrorRouteRestriction';
// route guards - end
import { InnerLayoutContainer } from 'containers/layouts/InnerLayoutContainer';
// identities - start
import { IdentitiesPage } from 'containers/identities/IdentitiesPage';
import { IdentityDetailsPage } from 'containers/identities/IdentityDetailsPage';
import { BeneficialOwnerIdentityDetailsPage } from 'containers/identities/BeneficialOwnerIdentityDetailsPage';
// identities - end
// tapi
import { CustodialAccountsPage } from 'containers/tapi/custodialAccounts/CustodialAccountsPage';
import { CustodialAccountsDetailsPage } from 'containers/tapi/custodialAccounts/CustodialAccountsDetailsPage';
import { TapiTransactionsPage } from 'containers/tapi/transactions/TapiTransactionsPage';
import { TapiTransactionDetailsPage } from 'containers/tapi/transactions/TapiTransactionDetailsPage';
// wapi
//  TODO: Reveal when wallet transaction feature will be ready for release
// import { WapiTransactionsPage } from 'containers/wapi/transactions/WapiTransactionsPage';
// import { WapiTransactionDetailsPage } from 'containers/wapi/transactions/WapiTransactionDetailsPage';
import { WalletsPage } from 'containers/wapi-deprecated/wallets/WalletsPage';
import { WalletDetailsPage } from 'containers/wapi-deprecated/wallets/WalletDetailsPage';
import TokenDetailsPage from 'containers/wapi-deprecated/tokens/TokenDetailsPage';
// global
import { ErrorPage } from 'components/global/ErrorPage';
// settings - start
import { SettingsPage } from 'containers/settings/SettingsPage';
// settings - end
// users - start
import { UserDetailsPage } from 'containers/settings/users/UserDetailsPage';
import { DashboardRoles } from 'models/enums/user/dashboard-roles';
import { featureFlags } from 'utils/feature-flags';
// users - end

const innerRoutes = (): React.ReactNode => (
  <Route element={<PrivateRoutesRestriction />}>
    <Route element={<InnerLayoutContainer />}>
      <Route path={PATHS.INDEX} element={<Index />} />
      {/* Inside PermissionRoutesRestriction - start */}
      <Route
        element={
          <PermissionRoutesRestriction
            allowedSubApis={[
              // All
              SubApiCases.trustWallet,
              SubApiCases.trustWalletCompliance,
              // Trust Api
              SubApiCases.trust,
              SubApiCases.trustCompliance,
              // Wallet API with Identities
              SubApiCases.walletCompliance,
              // Wallet API without Identities
              SubApiCases.wallet,
              // Default permissions (everything is allowed)
              SubApiCases.default,
            ]}
          />
        }
      >
        <Route path={ROUTE_SEGMENTS.IDENTITIES}>
          <Route index element={<IdentitiesPage />} />
          <Route path={ROUTE_SEGMENTS.IDENTITY_DETAILS}>
            <Route index element={<IdentityDetailsPage />} />
            {featureFlags.isWalletEnabled && (
              <Route path={ROUTE_SEGMENTS.WAPI}>
                <Route path={ROUTE_SEGMENTS.WALLETS}>
                  <Route path={ROUTE_SEGMENTS.WALLET_DETAILS}>
                    <Route index element={<WalletDetailsPage />} />
                    <Route path={ROUTE_SEGMENTS.TOKENS}>
                      <Route path={ROUTE_SEGMENTS.TOKEN_DETAILS} element={<TokenDetailsPage />} />
                    </Route>
                  </Route>
                </Route>
              </Route>
            )}
            <Route path={ROUTE_SEGMENTS.TAPI}>
              <Route path={ROUTE_SEGMENTS.CUSTODIAL_ACCOUNTS}>
                <Route path={ROUTE_SEGMENTS.CUSTODIAL_ACCOUNTS_DETAILS}>
                  <Route index element={<CustodialAccountsDetailsPage />} />
                  <Route path={ROUTE_SEGMENTS.B_DETAILS} element={<IdentityDetailsPage />} />
                  <Route path={ROUTE_SEGMENTS.AO_DETAILS} element={<IdentityDetailsPage />} />
                  <Route path={ROUTE_SEGMENTS.TRANSACTIONS}>
                    <Route path={ROUTE_SEGMENTS.TRANSACTION_DETAILS} element={<TapiTransactionDetailsPage />} />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path={ROUTE_SEGMENTS.BENEFICIAL_OWNERS}>
              <Route path={ROUTE_SEGMENTS.BO_DETAILS} element={<BeneficialOwnerIdentityDetailsPage />} />
            </Route>
          </Route>
        </Route>
      </Route>
      {/* Inside PermissionRoutesRestriction - end */}

      {/* Inside PermissionRoutesRestriction - start */}
      <Route
        element={
          <PermissionRoutesRestriction
            allowedSubApis={[
              // All
              SubApiCases.trustWallet,
              SubApiCases.trustWalletCompliance,
              // Wallet API with Identities
              SubApiCases.walletCompliance,
              // Wallet API without Identities
              SubApiCases.wallet,
              // Default permissions (everything is allowed)
              SubApiCases.default,
            ]}
          />
        }
      >
        {featureFlags.isWalletEnabled && (
          <Route path={ROUTE_SEGMENTS.WAPI}>
            {/* TODO: Reveal when wallet transaction feature will be ready for release
             <Route path={ROUTE_SEGMENTS.TRANSACTIONS}>
              <Route index element={<WapiTransactionsPage />} />
              <Route path={ROUTE_SEGMENTS.TRANSACTION_DETAILS} element={<WapiTransactionDetailsPage />} />
            </Route> */}
            <Route path={ROUTE_SEGMENTS.WALLETS}>
              <Route index element={<WalletsPage />} />
              <Route path={ROUTE_SEGMENTS.WALLET_DETAILS}>
                <Route index element={<WalletDetailsPage />} />
                <Route path={ROUTE_SEGMENTS.TOKENS}>
                  <Route path={ROUTE_SEGMENTS.TOKEN_DETAILS} element={<TokenDetailsPage />} />
                </Route>
              </Route>
            </Route>
          </Route>
        )}
      </Route>
      {/* Inside PermissionRoutesRestriction - end */}

      {/* Inside PermissionRoutesRestriction - start */}
      <Route
        element={
          <PermissionRoutesRestriction
            allowedSubApis={[
              // All
              SubApiCases.trustWallet,
              SubApiCases.trustWalletCompliance,
              // Trust Api
              SubApiCases.trust,
              SubApiCases.trustCompliance,
              // Default permissions (everything is allowed)
              SubApiCases.default,
            ]}
          />
        }
      >
        <Route path={ROUTE_SEGMENTS.TAPI}>
          <Route path={ROUTE_SEGMENTS.CUSTODIAL_ACCOUNTS}>
            <Route element={<CustodialAccountsPageRestriction />}>
              <Route index element={<CustodialAccountsPage />} />
            </Route>
            <Route path={ROUTE_SEGMENTS.CUSTODIAL_ACCOUNTS_DETAILS}>
              <Route index element={<CustodialAccountsDetailsPage />} />
              <Route path={ROUTE_SEGMENTS.IDENTITIES}>
                <Route path={ROUTE_SEGMENTS.IDENTITY_DETAILS} element={<IdentityDetailsPage />} />
              </Route>
              <Route path={ROUTE_SEGMENTS.AO_DETAILS} element={<IdentityDetailsPage />} />
              <Route path={ROUTE_SEGMENTS.TRANSACTIONS}>
                <Route path={ROUTE_SEGMENTS.TRANSACTION_DETAILS} element={<TapiTransactionDetailsPage />} />
              </Route>
            </Route>
          </Route>
          <Route path={ROUTE_SEGMENTS.TRANSACTIONS}>
            <Route index element={<TapiTransactionsPage />} />
            <Route path={ROUTE_SEGMENTS.TRANSACTION_DETAILS} element={<TapiTransactionDetailsPage />} />
          </Route>
        </Route>
      </Route>
      {/* Inside PermissionRoutesRestriction - end */}
      {/* Settings - start */}
      <Route element={<PermissionBasedAccessRestriction permissionsAllowingAccess={[DashboardRoles.userManager]} />}>
        <Route path={ROUTE_SEGMENTS.SETTINGS}>
          <Route index element={<SettingsPage />} />
          <Route path={ROUTE_SEGMENTS.USERS}>
            <Route path={ROUTE_SEGMENTS.USER_DETAILS} element={<UserDetailsPage />} />
          </Route>
        </Route>
      </Route>
      {/* Settings - end */}
    </Route>
    <Route element={<ErrorRouteRestriction />}>
      <Route element={<ErrorPage href={CONTACT_US_HREF} />} path={ROUTE_SEGMENTS.ERROR} />
    </Route>
  </Route>
);

export default innerRoutes;
