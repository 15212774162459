import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { RightCellBox } from 'components/shared/detail-page/rows/shared/RightCellBox';

type Props = {
  value: string;
  url?: string;
};

/** @deprecated */
export const RightCell: React.FC<Props> = ({ value, url }) => {
  return (
    <RightCellBox>
      <Link target="_blank" rel="noopener noreferrer" href={url} sx={{ cursor: 'pointer' }}>
        <Typography variant="bodyDefaultBookLink">{value}</Typography>
      </Link>
    </RightCellBox>
  );
};
