import i18n from 'localizations';
import { WalletAssetRow } from 'ui-interfaces/row-interfaces/wallets-deprecated/wallet-asset-row';
import { WalletAssetResponseModel } from 'models/response/wallets-deprecated/wallet-asset-response-model';
import { getNetworkChainLabel } from 'utils/labels-mapping/get-network-chain-label';
import { ICryptoAssetsNameCell } from 'ui-interfaces/wallets-deprecated/i-crypto-assets-name-cell';
import { AssetByNetworkIconSize } from 'ui-enums/asset-by-network-icon-size';
import { mapAssetByNetworkIconSizeToNumber } from 'utils/multi-mapping/asset-by-network-icon-size/map-asset-by-network-icon-size-to-number';
import { mapAssetByNetworkIconSizeToDefaultAssetIcon } from 'utils/icons-mapping/mapAssetByNetworkIconSizeToDefaultAssetIcon';
import { capitalizeFirstLetter } from 'utils/capitalize-first-letter';
import { generateRandomUUID } from 'utils/generate-random-uuid';

/** @deprecated */
export class WalletAssetViewModel implements WalletAssetRow {
  readonly id: string;

  readonly cryptoAssetsNameCellProps: ICryptoAssetsNameCell;

  readonly address: string;

  readonly blockchain: string;

  readonly balance: string;

  private static readonly iconSize = AssetByNetworkIconSize['36px'];

  constructor(walletAsset: WalletAssetResponseModel) {
    this.id = walletAsset.id;
    this.cryptoAssetsNameCellProps = {
      asset: {
        id: generateRandomUUID(),
        imageUrl: walletAsset.xsAssetIcon,
        shortName: walletAsset.assetTicker
          ? walletAsset.assetTicker.toUpperCase()
          : i18n.t('walletsPage.defaultAssetTicker'),
        longName: walletAsset.assetName
          ? capitalizeFirstLetter(walletAsset.assetName)
          : i18n.t('walletsPage.defaultAssetName'),
        defaultAssetIcon: mapAssetByNetworkIconSizeToDefaultAssetIcon(WalletAssetViewModel.iconSize),
      },
      size: mapAssetByNetworkIconSizeToNumber(WalletAssetViewModel.iconSize),
    };
    this.address = walletAsset.address;
    this.blockchain = getNetworkChainLabel(walletAsset.network);
    this.balance = walletAsset.balance;
  }
}
