export const PATHS = {
  INDEX: '/',

  // Auth Routes
  LOG_IN: '/login',

  // Inner Routes
  IDENTITIES: '/identities',
  IDENTITY_DETAILS: '/identities/:identityId/:identityType/details',
  TAPI: {
    CUSTODIAL_ACCOUNTS: '/tapi/accounts',
    CUSTODIAL_ACCOUNTS_DETAILS: '/tapi/accounts/:custodialAccountId/details',
    TRANSACTIONS: '/tapi/transactions',
    TRANSACTION_DETAILS: '/tapi/transactions/:transactionId/details',
  },
  WAPI: {
    WALLETS: '/wapi/wallets',
    WALLET_DETAILS: '/wapi/wallets/:walletId/details',
    TRANSACTIONS: '/wapi/transactions',
    TRANSACTION_DETAILS: '/wapi/transactions/:transactionId/details',
    TOKEN_DETAILS: '/wapi/wallets/:walletId/details/tokens/:tokenId/details',
  },
  SETTINGS: '/settings',
  USER_DETAILS: '/settings/users/:userId/details',
  GLOBAL: {
    ERROR: '/error',
  },
};

export const DEFAULT_ROUTE = PATHS.INDEX;

export const ROUTE_SEGMENTS = {
  INDEX: '/',
  ERROR: '/error',
  /** @deprecated */
  WAPI: 'wapi',
  TAPI: 'tapi',
  IDENTITIES: 'identities',
  CUSTODIAL_ACCOUNTS: 'accounts',
  BENEFICIAL_OWNERS: 'beneficial-owners',
  TRANSACTIONS: 'transactions',
  /** @deprecated */
  WALLETS: 'wallets',
  IDENTITY_DETAILS: ':identityId/:identityType/details',
  BO_DETAILS: ':parentIdentityId/:parentIdentityType/bo-details', // Beneficial Owner details
  B_DETAILS: ':parentIdentityId/:parentIdentityType/b-details', // Beneficiary details
  AO_DETAILS: ':identityId/:identityType/ao-details', // Account Owner details
  AO_DETAILS_UNDER_IDENTITY_DETAILS: ':parentIdentityId/:parentIdentityType/ao-details', // Account Owner details
  CUSTODIAL_ACCOUNTS_DETAILS: ':custodialAccountId/details',
  TRANSACTION_DETAILS: ':transactionId/details',
  /** @deprecated */
  WALLET_DETAILS: ':walletId/details',
  /** @deprecated */
  TOKENS: 'TOKENS',
  /** @deprecated */
  TOKEN_DETAILS: ':tokenId/details',
  SETTINGS: 'settings',
  USERS: 'users',
  USER_DETAILS: ':userId/details',
};

// /identities/:identityId/:identityType/details/tapi/accounts/:custodialAccountId/details
const IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS = `/${ROUTE_SEGMENTS.IDENTITIES}/${ROUTE_SEGMENTS.IDENTITY_DETAILS}/${ROUTE_SEGMENTS.TAPI}/${ROUTE_SEGMENTS.CUSTODIAL_ACCOUNTS}/${ROUTE_SEGMENTS.CUSTODIAL_ACCOUNTS_DETAILS}`;
// .../transactions/:transactionId/details`;
const IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS__TRANSACTION_DETAILS = `${IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS}/${ROUTE_SEGMENTS.TRANSACTIONS}/${ROUTE_SEGMENTS.TRANSACTION_DETAILS}`;
// tapi/accounts/:custodialAccountId/details/transactions/:transactionId/details
const CUSTODIAL_ACCOUNTS_DETAILS__TRANSACTION_DETAILS = `/${ROUTE_SEGMENTS.TAPI}/${ROUTE_SEGMENTS.CUSTODIAL_ACCOUNTS}/${ROUTE_SEGMENTS.CUSTODIAL_ACCOUNTS_DETAILS}/${ROUTE_SEGMENTS.TRANSACTIONS}/${ROUTE_SEGMENTS.TRANSACTION_DETAILS}`;
// tapi/accounts/:custodialAccountId/details/identities/:identityId/:identityType/details
const CUSTODIAL_ACCOUNTS_DETAILS__IDENTITY_DETAILS = `/${ROUTE_SEGMENTS.TAPI}/${ROUTE_SEGMENTS.CUSTODIAL_ACCOUNTS}/${ROUTE_SEGMENTS.CUSTODIAL_ACCOUNTS_DETAILS}/${ROUTE_SEGMENTS.IDENTITIES}/${ROUTE_SEGMENTS.IDENTITY_DETAILS}`;
// tapi/accounts/:custodialAccountId/details/identities/:identityId/:identityType/ao-details
const CUSTODIAL_ACCOUNTS_DETAILS__ACCOUNT_OWNER_DETAILS = `/${ROUTE_SEGMENTS.TAPI}/${ROUTE_SEGMENTS.CUSTODIAL_ACCOUNTS}/${ROUTE_SEGMENTS.CUSTODIAL_ACCOUNTS_DETAILS}/${ROUTE_SEGMENTS.AO_DETAILS}`;
// /identities/:identityId/:identityType/details/wapi/wallets/:walletId/details
const IDENTITY_DETAILS__WALLET_DETAILS = `/${ROUTE_SEGMENTS.IDENTITIES}/${ROUTE_SEGMENTS.IDENTITY_DETAILS}/${ROUTE_SEGMENTS.WAPI}/${ROUTE_SEGMENTS.WALLETS}/${ROUTE_SEGMENTS.WALLET_DETAILS}`;
// /identities/:identityId/:identityType/details/wapi/wallets/:walletId/details/tokens/:tokenId/details
const IDENTITY_DETAILS__TOKEN_DETAILS = `/${ROUTE_SEGMENTS.IDENTITIES}/${ROUTE_SEGMENTS.IDENTITY_DETAILS}/${ROUTE_SEGMENTS.WAPI}/${ROUTE_SEGMENTS.WALLETS}/${ROUTE_SEGMENTS.WALLET_DETAILS}/${ROUTE_SEGMENTS.TOKENS}/${ROUTE_SEGMENTS.TOKEN_DETAILS}`;
// identities/:identityId/:identityType/details/:parentIdentityId/:parentIdentityType/bo-details
const IDENTITY_DETAILS__BENEFICIAL_OWNER_DETAILS = `/${ROUTE_SEGMENTS.IDENTITIES}/${ROUTE_SEGMENTS.IDENTITY_DETAILS}/${ROUTE_SEGMENTS.BENEFICIAL_OWNERS}/${ROUTE_SEGMENTS.BO_DETAILS}`;
// /identities/:identityId/:identityType/details/tapi/accounts/:custodialAccountId/details/:parentIdentityId/:parentIdentityType/b-details
const IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS__BENEFICIARY_DETAILS = `${IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS}/${ROUTE_SEGMENTS.B_DETAILS}`;
// /identities/:identityId/:identityType/details/tapi/accounts/:custodialAccountId/details/:parentIdentityId/:parentIdentityType/ao-details
const IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS__ACCOUNT_OWNER_DETAILS = `${IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS}/${ROUTE_SEGMENTS.AO_DETAILS_UNDER_IDENTITY_DETAILS}`;
// /settings/users/:userId/details
const SETTINGS__USER_DETAILS = `/${ROUTE_SEGMENTS.SETTINGS}/${ROUTE_SEGMENTS.USERS}/${ROUTE_SEGMENTS.USER_DETAILS}`;

export const NESTED_PATHS = {
  IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS,
  IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS__TRANSACTION_DETAILS,
  CUSTODIAL_ACCOUNTS_DETAILS__TRANSACTION_DETAILS,
  IDENTITY_DETAILS__WALLET_DETAILS,
  IDENTITY_DETAILS__TOKEN_DETAILS,
  IDENTITY_DETAILS__BENEFICIAL_OWNER_DETAILS,
  IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS__BENEFICIARY_DETAILS,
  IDENTITY_DETAILS__CUSTODIAL_ACCOUNT_DETAILS__ACCOUNT_OWNER_DETAILS,
  CUSTODIAL_ACCOUNTS_DETAILS__IDENTITY_DETAILS,
  CUSTODIAL_ACCOUNTS_DETAILS__ACCOUNT_OWNER_DETAILS,
  SETTINGS__USER_DETAILS,
};

export const CONTACT_US_HREF = 'https://fortresstrust.com/contact';
