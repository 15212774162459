import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUser } from 'redux/api/api-users';
import ContentBlock from 'components/wapi-deprecated/wallets/WalletsPage';
import { WalletResponseModel } from 'models/response/wallets-deprecated/wallet-response-model';
import { FilterResult } from 'filters/interfaces/filter-result';
import { useExport } from 'hooks/use-export';
import { useTransformCollectionResponse } from 'hooks/use-transform-collection-response';
import { useLazyGetWalletsQuery } from 'redux/api/api-wallets';
import { WalletRow } from 'ui-interfaces/row-interfaces/wallets-deprecated';
import { checkIfAccessAllowed } from 'utils/sub-apis/check-if-access-allowed';
import { SubApiCases } from 'navigation/sub-api-cases';
import { onWalletTransform, onWalletRowClick, onWalletsExportClick } from './handlers';

/** @deprecated */
export const WalletsPage: React.FC = () => {
  // Check for Allowed SubApis - start
  const { data: user } = useSelector(getUser.select(undefined));
  const [isOwnerShown, setIsOwnerShown] = useState(false);

  useEffect(() => {
    if (user?.organization?.allowedSubApis) {
      const shouldOwnerBeShown = checkIfAccessAllowed(
        [
          SubApiCases.trustCompliance,
          SubApiCases.trustWalletCompliance,
          SubApiCases.walletCompliance,
          SubApiCases.default,
        ],
        user?.organization?.allowedSubApis,
      );
      setIsOwnerShown(shouldOwnerBeShown);
    }
  }, [user]);
  // Check for Allowed SubApis - end

  const navigate = useNavigate();
  const { downloadCsv, isLoading: isExportLoading, error: exportError } = useExport();

  const [getWalletsTrigger, walletsResult] = useLazyGetWalletsQuery();

  const fetchWallets = useCallback(
    (page?: number, filterResult?: FilterResult) => getWalletsTrigger({ page, filterResult }, false),
    [getWalletsTrigger],
  );

  const [walletRows, walletsResourceCount] = useTransformCollectionResponse<WalletResponseModel, WalletRow>(
    walletsResult.data,
    onWalletTransform,
  );

  useEffect(() => {
    fetchWallets();
  }, [fetchWallets]);

  return (
    <ContentBlock
      walletsGridProps={{
        rows: walletRows,
        rowCount: walletsResourceCount,
        isLoading: walletsResult.isFetching,
        error: walletsResult.error || exportError,
        fetchOtherItems: fetchWallets,
        onGridRowClick: onWalletRowClick(navigate),
        onExportClick: onWalletsExportClick(downloadCsv),
        isExportLoading,
      }}
      isOwnerShown={isOwnerShown}
    />
  );
};
