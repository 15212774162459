import React from 'react';
import { PathMatch } from 'react-router-dom';

import { TokensGrid } from './TokensGrid';
//  TODO: Reveal when wallet transaction feature will be ready for release
// import { TokensTransactionsGrid } from './TokensTransactionsGrid';

type Props = {
  walletId: string;
  pathMatch: PathMatch<string> | null;
  setIsDetailsPageError: React.Dispatch<React.SetStateAction<boolean>>;
};

/** @deprecated */
export const TokensTab: React.FC<Props> = ({ walletId, pathMatch, setIsDetailsPageError }) => (
  <>
    <TokensGrid walletId={walletId} pathMatch={pathMatch} setIsDetailsPageError={setIsDetailsPageError} />
    {/* TODO: Reveal when wallet transaction feature will be ready for release */}
    {/* <TokensTransactionsGrid /> */}
  </>
);
