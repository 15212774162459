import React, { useState } from 'react';
import { ReactComponent as OtherIcon } from 'assets/icons/tokens/token-default-36x36/other.svg';
import { FileType } from 'ui-enums/file-type';
import { getDefaultIconForToken } from './getDefaultIconForToken';
import { StyledImage } from './styled';

type Props = {
  fileType?: string;
  url?: string;
};

/** @deprecated */
export const TokenImage: React.FC<Props> = ({ fileType, url }) => {
  const [isError, setIsError] = useState(false);
  if (isError || !url) {
    return fileType ? getDefaultIconForToken(fileType as FileType) : <OtherIcon />;
  }
  return (
    <StyledImage
      src={url}
      onLoad={() => {
        setIsError(false);
      }}
      onError={() => {
        setIsError(true);
      }}
    />
  );
};
