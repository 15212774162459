import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CommonLayoutContainer, CommonLayoutHeader } from 'components/shared/layouts/CommonLayout';
import { Gap } from 'components/shared/Gap';
import { StyledTab } from 'components/shared/Tabs';
import { BreadCrumbsItems } from 'components/shared/Breadcrumbs';
import { StyledHeaderBox, StyledTabsBox } from 'components/identities/styled';
import { IdentitiesDetailsPageTabs } from 'ui-enums/tabs/identities/identities-details-page-tabs';
import { IIdentityDetailsPage } from 'ui-interfaces/identity-details/i-identity-details-page';
import { IdentityDetailsUrlParams } from 'navigation/url-params-type';
import { CreateCustodianAccountRequestModel } from 'models/request/custodial-accounts/create-custodial-account-request-model';
import { MessageType, setBannerData } from 'redux/banner';
import { useCreateCustodialAccountMutation } from 'redux/api/api-custodial-accounts';
import { usePreviousValue } from 'hooks/usePreviousValue';
import { AccountBeneficiaryRequestModel } from 'models/request/custodial-accounts/account-beneficiary-request-model';
import { getIsIra } from 'utils/custodial-accounts/beneficiaries/get-is-ira';
import { CustodialAccountsTab } from './Tabs/CustodialAccountsTab';
import { IdentityDetailsPageHeader } from './IdentityDetailsPageHeader';
import { IdentityDetailsPageTitleRow } from './IdentityDetailsPageTitleRow';
import { IdentityDetailsTab } from './Tabs/IdentityDetailsTab';
import { WalletsTab } from './Tabs/WalletsTab-deprecated';
import { Error } from './layout/Error';
import { SuspensionIdentityModal } from './SuspensionIdentityModal';
import { IdentityDetailsPageTopRow } from './IdentityDetailsPageTopRow';
import { AddAccountModal } from './AddAccountModal';
import BeneficiariesListModal from './BeneficiariesListModal';
import BeneficiaryInfoModal from './BeneficiaryInfoModal';

const REQUIRED_TOTAL_PERCENTAGE = 100;

type Props = {
  tab: IdentitiesDetailsPageTabs;
  urlParams: Readonly<Partial<IdentityDetailsUrlParams>>;
  setIsDetailsPageError: React.Dispatch<React.SetStateAction<boolean>>;
  handleChange: (event: React.SyntheticEvent, newTab: IdentitiesDetailsPageTabs) => void;
  breadCrumbs: BreadCrumbsItems;
  loading: boolean;
  error: boolean;
  identityDetailsPage?: IIdentityDetailsPage;
};

export const IdentityDetailsPageContent: React.FC<Props> = ({
  tab,
  urlParams,
  setIsDetailsPageError,
  handleChange,
  breadCrumbs,
  loading,
  error,
  identityDetailsPage,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // suspension modal - start
  const [isSuspensionModalOpen, setIsSuspensionModalOpen] = useState(false);

  const showSuspensionModal = () => setIsSuspensionModalOpen(true);
  const closeSuspensionModal = useCallback(() => setIsSuspensionModalOpen(false), []);
  // suspension modal - end

  const [createCustodialAccount, { isLoading, isSuccess: isCreateCustodialAccountSuccess }] =
    useCreateCustodialAccountMutation();

  const [createCustodialAccountData, setCreateCustodialAccountData] = useState<CreateCustodianAccountRequestModel>();
  const [agreementCheck, setAgreementCheck] = useState(false);

  const onSubmitCustodialAccount = (data: CreateCustodianAccountRequestModel) => {
    const formData = {
      ...createCustodialAccountData,
      ...data,
    };

    if (formData.beneficiaries?.length) {
      const totalPercentage = formData.beneficiaries.reduce(
        (sum, beneficiary) => sum + beneficiary.percentageOfDivision,
        0,
      );
      if (totalPercentage !== REQUIRED_TOTAL_PERCENTAGE) {
        dispatch(
          setBannerData({ type: MessageType.error, message: t('identityDetailsPage.addAccountModal.percentageError') }),
        );
        return;
      }
    }
    createCustodialAccount(formData);
  };

  const onCancelClick = (closeHandler: () => void) => {
    return () => {
      closeHandler();
      setAgreementCheck(false);
      setCreateCustodialAccountData(undefined);
    };
  };

  const prevIsCreateAccountSuccess = usePreviousValue(isCreateCustodialAccountSuccess);
  const isAccountCreationSucceeded = !prevIsCreateAccountSuccess && isCreateCustodialAccountSuccess;

  // add account modal - start
  const [isAddAccountModalOpen, setIsAddAccountModalOpen] = useState(false);

  const showAddAccountModal = () => setIsAddAccountModalOpen(true);
  const closeAddAccountModal = useCallback(() => {
    setIsAddAccountModalOpen(false);
  }, []);

  const onAddAccountModalContinue = (data: CreateCustodianAccountRequestModel) => {
    closeAddAccountModal();
    showBeneficiariesListModal();
    setCreateCustodialAccountData(prevState => ({ ...prevState, ...data }));
  };
  // add account modal - end

  // beneficiaries list modal - start
  const [isBeneficiariesListModalOpen, setIsBeneficiariesListModalOpen] = useState(false);
  const [currentBeneficiaryData, setCurrentBeneficiaryData] = useState<AccountBeneficiaryRequestModel>();

  const showBeneficiariesListModal = () => setIsBeneficiariesListModalOpen(true);
  const closeBeneficiariesListModal = useCallback(() => setIsBeneficiariesListModalOpen(false), []);

  const onBeneficiariesListModalSubmit = () => {
    if (createCustodialAccountData) {
      onSubmitCustodialAccount(createCustodialAccountData);
    }
  };

  const onAddBeneficiary = () => {
    closeBeneficiariesListModal();
    showBeneficiariesInfoModal();
  };

  const onEditBeneficiary = (email: string) => {
    if (createCustodialAccountData && createCustodialAccountData.beneficiaries?.length) {
      const editBeneficiary = createCustodialAccountData.beneficiaries.find(beneficiary => email === beneficiary.email);
      setCurrentBeneficiaryData(editBeneficiary);
    }
    closeBeneficiariesListModal();
    showBeneficiariesInfoModal();
  };

  const onBeneficiariesListModalBack = () => {
    closeBeneficiariesListModal();
    showAddAccountModal();
  };
  // beneficiaries list modal - end

  // beneficiaries info modal - start
  const [isBeneficiariesInfoModalOpen, setIsBeneficiariesInfoModalOpen] = useState(false);

  const showBeneficiariesInfoModal = () => setIsBeneficiariesInfoModalOpen(true);
  const closeBeneficiariesInfoModal = useCallback(() => {
    setCurrentBeneficiaryData(undefined);
    setIsBeneficiariesInfoModalOpen(false);
  }, []);

  const addBeneficiary = (data: AccountBeneficiaryRequestModel) => {
    setCreateCustodialAccountData(prevState => {
      if (prevState?.beneficiaries) {
        return { ...prevState, beneficiaries: [...prevState.beneficiaries, data] };
      }
      if (prevState?.type && getIsIra(prevState.type)) {
        return { ...prevState, beneficiaries: [data] };
      }
      return prevState;
    });
    showBeneficiariesListModal();
    closeBeneficiariesInfoModal();
  };

  const updateBeneficiary = (data: AccountBeneficiaryRequestModel, email: string) => {
    setCreateCustodialAccountData(prevState => {
      if (prevState?.beneficiaries) {
        const beneficiaries = prevState.beneficiaries.map(beneficiary =>
          beneficiary.email === email ? { ...beneficiary, ...data } : beneficiary,
        );
        return { ...prevState, beneficiaries };
      }
      return prevState;
    });
    showBeneficiariesListModal();
    closeBeneficiariesInfoModal();
  };

  const removeBeneficiary = (email: string) => {
    setCreateCustodialAccountData(prevState => {
      if (prevState?.beneficiaries) {
        const beneficiaries = prevState.beneficiaries.filter(beneficiary => beneficiary.email !== email);
        return { ...prevState, beneficiaries };
      }
      return prevState;
    });
    showBeneficiariesListModal();
    closeBeneficiariesInfoModal();
  };

  const onBeneficiariesInfoModalBack = () => {
    closeBeneficiariesInfoModal();
    showBeneficiariesListModal();
  };
  // beneficiaries info modal - end

  useEffect(() => {
    if (isAccountCreationSucceeded) {
      closeAddAccountModal();
      closeBeneficiariesListModal();
      if (!isAddAccountModalOpen && !isBeneficiariesListModalOpen) {
        setCreateCustodialAccountData(undefined);
        setAgreementCheck(false);
      }
    }
  }, [
    closeAddAccountModal,
    closeBeneficiariesListModal,
    isAccountCreationSucceeded,
    isAddAccountModalOpen,
    isBeneficiariesListModalOpen,
  ]);

  if (error) {
    return (
      <Error
        breadCrumbs={breadCrumbs}
        tab={tab}
        handleChange={handleChange}
        isCustodialAccountsTabShown={identityDetailsPage?.isCustodialAccountsTabShown}
        isWalletsTabShown={identityDetailsPage?.isWalletsTabShown}
      />
    );
  }

  return (
    <CommonLayoutContainer>
      <CommonLayoutHeader>
        <StyledHeaderBox>
          <IdentityDetailsPageTopRow
            loading={loading}
            showSuspensionModal={showSuspensionModal}
            showAddAccountModal={showAddAccountModal}
            breadCrumbs={breadCrumbs}
            data={identityDetailsPage}
          />
          <Gap size="_20px" />
          <IdentityDetailsPageTitleRow data={identityDetailsPage?.pageTitle} loading={loading} />
          <Gap size="_20px" />
          <IdentityDetailsPageHeader data={identityDetailsPage?.pageHeader} loading={loading} />
          <Gap size="_40px" />
          <StyledTabsBox value={tab} onChange={handleChange}>
            <StyledTab label={t('identityDetailsPage.tabs.detailsTab')} value={IdentitiesDetailsPageTabs.details} />
            {identityDetailsPage?.isCustodialAccountsTabShown && (
              <StyledTab
                label={t('identityDetailsPage.tabs.accountsTab')}
                value={IdentitiesDetailsPageTabs.custodialAccounts}
              />
            )}
            {identityDetailsPage?.isWalletsTabShown && (
              <StyledTab label={t('identityDetailsPage.tabs.walletsTab')} value={IdentitiesDetailsPageTabs.wallets} />
            )}
          </StyledTabsBox>
        </StyledHeaderBox>
      </CommonLayoutHeader>
      <>
        {tab === IdentitiesDetailsPageTabs.details && (
          <IdentityDetailsTab
            urlParams={urlParams}
            identityDetailsPage={identityDetailsPage}
            setIsDetailsPageError={setIsDetailsPageError}
            loading={loading}
          />
        )}
        {tab === IdentitiesDetailsPageTabs.custodialAccounts && (
          <CustodialAccountsTab urlParams={urlParams} setIsDetailsPageError={setIsDetailsPageError} />
        )}
        {tab === IdentitiesDetailsPageTabs.wallets && (
          <WalletsTab urlParams={urlParams} setIsDetailsPageError={setIsDetailsPageError} />
        )}
      </>
      <SuspensionIdentityModal
        isOpen={isSuspensionModalOpen}
        onClose={closeSuspensionModal}
        isSuspendMode={identityDetailsPage?.isSuspendMode ?? false}
        name={identityDetailsPage?.pageTitle.title ?? ''}
        urlParams={urlParams}
      />
      <AddAccountModal
        isOpen={isAddAccountModalOpen}
        onClose={onCancelClick(closeAddAccountModal)}
        name={identityDetailsPage?.pageTitle.title ?? ''}
        urlParams={urlParams}
        isLoading={isLoading}
        onContinue={onAddAccountModalContinue}
        onSubmit={onSubmitCustodialAccount}
        initialData={createCustodialAccountData}
        agreementCheck={agreementCheck}
        updateAgreementCheck={setAgreementCheck}
      />
      <BeneficiariesListModal
        isOpen={isBeneficiariesListModalOpen}
        onClose={onCancelClick(closeBeneficiariesListModal)}
        beneficiaries={createCustodialAccountData?.beneficiaries || []}
        isLoading={isLoading}
        onDone={onBeneficiariesListModalSubmit}
        onBack={onBeneficiariesListModalBack}
        onAddBeneficiary={onAddBeneficiary}
        onEditBeneficiary={onEditBeneficiary}
      />
      <BeneficiaryInfoModal
        isOpen={isBeneficiariesInfoModalOpen}
        onClose={onCancelClick(closeBeneficiariesInfoModal)}
        onBack={onBeneficiariesInfoModalBack}
        ownerInfo={currentBeneficiaryData}
        onAddOwner={addBeneficiary}
        onEditOwner={updateBeneficiary}
        onRemoveOwner={removeBeneficiary}
      />
    </CommonLayoutContainer>
  );
};
