import React, { useState } from 'react';
import { FileType } from 'ui-enums/file-type';
import { ImageThumbnailPlaceholder } from './ImageThumbnailPlaceholder';
import { StyledContainer, StyledImage } from './styled';

type Props = {
  mediaType: FileType;
  imageUrl?: string;
};

/** @deprecated */
export const ImageThumbnail: React.FC<Props> = ({ mediaType, imageUrl }) => {
  const [loadingImageError, setLoadingImageError] = useState<boolean>(false);
  const handleErrorLoadImage = () => setLoadingImageError(true);

  const renderContent = () => {
    if (!imageUrl || loadingImageError) {
      return <ImageThumbnailPlaceholder type={mediaType} isLoadError={loadingImageError} />;
    }
    return <StyledImage src={imageUrl} alt="nft-thumbnail" onError={handleErrorLoadImage} />;
  };

  return <StyledContainer>{renderContent()}</StyledContainer>;
};
