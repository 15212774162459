import i18n from 'localizations';
import { AssetByNetwork } from 'ui-enums/response/asset-by-network';
import { capitalizeFirstLetter } from 'utils/capitalize-first-letter';

/** @deprecated */
export const getAssetLongNameForWalletsList = (assetName: string | undefined) => {
  if (!assetName) {
    return i18n.t('walletsPage.defaultAssetName');
  }

  switch (assetName) {
    case AssetByNetwork.nft: {
      return assetName.toUpperCase();
    }
    default: {
      return capitalizeFirstLetter(assetName);
    }
  }
};
