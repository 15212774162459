import React from 'react';
import { ReactComponent as ErrorImageIcon } from 'assets/icons/nft-details/image-type-error.svg';
import { ReactComponent as ErrorGifIcon } from 'assets/icons/nft-details/gif-type-error.svg';
import { ReactComponent as ErrorAudioIcon } from 'assets/icons/nft-details/audio-type-error.svg';
import { ReactComponent as ErrorVideoIcon } from 'assets/icons/nft-details/video-type-error.svg';
import { ReactComponent as ErrorFileIcon } from 'assets/icons/nft-details/pdf-type-error.svg';
import { ReactComponent as ErrorOtherIcon } from 'assets/icons/nft-details/other-type-error.svg';
import { ReactComponent as ErrorGraphics3DIcon } from 'assets/icons/nft-details/3D-type-error.svg';
import { FileType } from 'ui-enums/file-type';

/** @deprecated */
export const getFileTypeErrorIcon = (fileType: FileType): React.ReactNode => {
  switch (fileType) {
    case FileType['image/jpeg']:
    case FileType['image/tiff']:
    case FileType['image/webp']:
    case FileType['image/svg+xml']:
    case FileType['image/png']:
      return <ErrorImageIcon />;
    case FileType['image/gif']:
      return <ErrorGifIcon />;
    case FileType['audio/aac']:
    case FileType['audio/aiff']:
    case FileType['audio/flac']:
    case FileType['audio/mpeg']:
    case FileType['audio/ogg']:
    case FileType['audio/wav']:
    case FileType['audio/x-aiff']:
    case FileType['audio/x-aac']:
    case FileType['audio/x-flac']:
      return <ErrorAudioIcon />;
    case FileType['video/mp4']:
    case FileType['video/quicktime']:
    case FileType['video/webm']:
    case FileType['video/x-matroska']:
    case FileType['video/x-msvideo']:
      return <ErrorVideoIcon />;
    case FileType['application/pdf']:
    case FileType['application/msword']:
    case FileType['text/plain']:
      return <ErrorFileIcon />;
    case FileType['model/obj']:
    case FileType['application/x-3ds']:
    case FileType['image/x-3ds']:
    case FileType['application/x-blender']:
    case FileType['model/stl']:
    case FileType['model/x.stl-binary']:
    case FileType['model/x.stl-ascii']:
    case FileType['model/gltf+json']:
    case FileType['model/gltf-binary']:
      return <ErrorGraphics3DIcon />;
    default:
      return <ErrorOtherIcon />;
  }
};
