import React from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import i18n from 'localizations';
import { WalletRow } from 'ui-interfaces/row-interfaces/wallets-deprecated';
import { WalletMultiAssetsCell } from 'components/shared/Cells/WalletMultiAssetsCell-deprecated';
import { GridCellExpand } from 'components/shared/Cells/GridCellExpand';
import { OwnerCell } from './OwnerCell';

/** @deprecated */
export const columnsWalletsWithOwner: GridColDef[] = [
  {
    field: 'walletName',
    headerName: i18n.t('walletsPage.walletName'),
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
    ),
  },
  {
    field: 'walletId',
    headerName: i18n.t('walletsPage.walletId'),
    flex: 1.7,
  },
  {
    field: 'owner',
    headerName: i18n.t('walletsPage.owner'),
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      const { ownerTooltipMessage } = params.row as WalletRow;
      return (
        <OwnerCell value={params.value} width={params.colDef.computedWidth} tooltipMessage={ownerTooltipMessage} />
      );
    },
  },
  {
    field: 'dateCreated',
    headerName: i18n.t('walletsPage.date'),
    flex: 0.8,
  },
  {
    field: 'asset',
    headerName: i18n.t('walletsPage.assets'),
    flex: 0.7,
    renderCell: (params: GridRenderCellParams) => {
      const { walletMultiAssetsCellProps } = params.row as WalletRow;
      return <WalletMultiAssetsCell data={walletMultiAssetsCellProps} />;
    },
  },
];
