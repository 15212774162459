import React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { CommonTooltip } from 'components/shared/CommonTooltip';
import { shortenWithThreeDotsInTheMiddle } from 'utils/shorten-with-three-dots-in-the-middle';

const NUM_OF_CHARS = 20;
const NUM_OF_CHARS_SHOWN = 10;

type Props = {
  id?: string;
};

/** @deprecated */
export const IdCell: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation();

  if (!id) {
    return (
      <Typography color="grey.500" variant="bodyDefaultBook">
        {t('walletDetailsPage.tokens.emptyFields.tokenId')}
      </Typography>
    );
  }

  if (id.length <= NUM_OF_CHARS) {
    return <Typography variant="bodyDefaultBook">{id}</Typography>;
  }

  return (
    <CommonTooltip title={id} placement="top">
      <Typography variant="bodyDefaultBook">{shortenWithThreeDotsInTheMiddle(id, NUM_OF_CHARS_SHOWN)}</Typography>
    </CommonTooltip>
  );
};
