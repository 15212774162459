import React from 'react';
import { TokenDetailsRowType } from 'ui-enums/token-details/token-details-row-type';
// rows components - start
import { ImageLoadingRow } from 'components/wapi-deprecated/tokens/TokenDetailsPageContent/rows/ImageLoadingRow';
import { LoadingRow } from 'components/shared/detail-page/rows/LoadingRow';
import { PlainRow } from 'components/wapi-deprecated/tokens/TokenDetailsPageContent/rows/PlainRow';
import { LinkRow } from 'components/wapi-deprecated/tokens/TokenDetailsPageContent/rows/LinkRow';
import { BlockchainRow } from 'components/wapi-deprecated/tokens/TokenDetailsPageContent/rows/BlockchainRow';
import { FileTypeRow } from 'components/wapi-deprecated/tokens/TokenDetailsPageContent/rows/FileTypeRow';
import { ImageRow } from 'components/wapi-deprecated/tokens/TokenDetailsPageContent/rows/ImageRow';
import { PropertiesRow } from 'components/wapi-deprecated/tokens/TokenDetailsPageContent/rows/PropertiesRow';
// rows components - end
// rows interfaces - start
import { TokenDetailsRowIdentifiable } from 'ui-interfaces/token-details/token-details-row-identifiable';
import { IPlainRow } from 'ui-interfaces/token-details/rows/i-plain-row';
import { ILinkRow } from 'ui-interfaces/token-details/rows/i-link-row';
import { IBlockchainRow } from 'ui-interfaces/token-details/rows/i-blockchain-row';
import { IFileTypeRow } from 'ui-interfaces/token-details/rows/i-file-type-row';
import { IImageRow } from 'ui-interfaces/token-details/rows/i-image-row';
import { IPropertiesRow } from 'ui-interfaces/token-details/rows/i-properties-row';
// rows interfaces - end

/** @deprecated */
export const createTokenDetailsPageRow = (
  itemData: TokenDetailsRowIdentifiable,
  isLastItem: boolean,
): React.ReactElement | null => {
  switch (itemData.rowType) {
    case TokenDetailsRowType.plain: {
      const i = itemData as IPlainRow;
      return <PlainRow key={i.id} data={{ ...i }} hasBottomBorder={!isLastItem} />;
    }

    case TokenDetailsRowType.image: {
      const i = itemData as IImageRow;
      return <ImageRow key={i.id} data={{ ...i }} />;
    }

    case TokenDetailsRowType.link: {
      const i = itemData as ILinkRow;
      return <LinkRow key={i.id} data={{ ...i }} hasBottomBorder={!isLastItem} />;
    }

    case TokenDetailsRowType.blockchain: {
      const i = itemData as IBlockchainRow;
      return <BlockchainRow key={i.id} data={{ ...i }} hasBottomBorder={!isLastItem} />;
    }

    case TokenDetailsRowType.fileType: {
      const i = itemData as IFileTypeRow;
      return <FileTypeRow key={i.id} data={{ ...i }} hasBottomBorder={!isLastItem} />;
    }

    case TokenDetailsRowType.properties: {
      const i = itemData as IPropertiesRow;
      return <PropertiesRow key={i.id} data={{ ...i }} />;
    }

    case TokenDetailsRowType.loading:
      return <LoadingRow key={itemData.id} hasBottomBorder={!isLastItem} />;

    case TokenDetailsRowType.imageLoading:
      return <ImageLoadingRow key={itemData.id} />;

    default:
      return null;
  }
};
