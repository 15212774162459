import { NavigateFunction } from 'react-router-dom';
import { GridRowParams } from '@mui/x-data-grid';
import { WalletResponseModel } from 'models/response/wallets-deprecated/wallet-response-model';
import { WalletViewModel } from 'view-models/wallets-deprecated/wallet-vm';
import { getRouteWithParams } from 'utils/get-route-with-params';
import { WalletDetailsUrlParams } from 'navigation/url-params-type';
import { PATHS } from 'navigation/constants';
import { DownloadCSV } from 'hooks/use-export/interfaces';
import { FilterResult } from 'filters/interfaces/filter-result';
import { API_ROUTES } from 'redux/api/api-routes';

/** @deprecated */
export const onWalletTransform = (wallet: WalletResponseModel) => {
  return new WalletViewModel(wallet);
};

/** @deprecated */
export const onWalletRowClick = (navigate: NavigateFunction) => (params: GridRowParams) => {
  const { row } = params;
  navigate(getRouteWithParams(PATHS.WAPI.WALLET_DETAILS, { walletId: row.id } as WalletDetailsUrlParams));
};

/** @deprecated */
export const onWalletsExportClick = (downloadCsv: DownloadCSV) => (filterResult?: FilterResult) => {
  downloadCsv({ urlSegment: API_ROUTES.private.WALLETS, filterResult });
};
