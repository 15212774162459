import React from 'react';
import { Box, Typography } from '@mui/material';
import { TokenDetailsNode } from 'ui-interfaces/token-details/token-details-node';
import { GrayGap } from 'components/shared/detail-page/GrayGap';
import { StyledBox } from './styled';
import { createTokenDetailsPageRow } from './createTokenDetailsPageRow';

type Props = {
  hasBottomBorder: boolean;
  node: TokenDetailsNode;
};

/** @deprecated */
export const DetailNode: React.FC<Props> = ({ node, hasBottomBorder }) => {
  return (
    <Box>
      {node.title && (
        <StyledBox>
          <Typography variant="h7">{node.title}</Typography>
        </StyledBox>
      )}
      {node.items.map((i, index, originalArray) => {
        return createTokenDetailsPageRow(i, index === originalArray.length - 1);
      })}
      {hasBottomBorder && <GrayGap />}
    </Box>
  );
};
