import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useExport } from 'hooks/use-export';
import { CustomizedGrid } from 'components/shared/grids/CustomizedGrid';
import { CommonLayoutContainer, CommonLayoutContent, CommonGridBox } from 'components/shared/layouts/CommonLayout';
import { WalletRow } from 'ui-interfaces/row-interfaces/wallets-deprecated';
import { useLazyGetWalletsQuery } from 'redux/api/api-wallets';
import { FilterResult } from 'filters/interfaces/filter-result';
import { useTransformCollectionResponse } from 'hooks/use-transform-collection-response';
import { WalletResponseModel } from 'models/response/wallets-deprecated/wallet-response-model';
import { IdentityDetailsUrlParams } from 'navigation/url-params-type';
import { onWalletRowClick, onWalletsExportClick, onWalletTransform } from './handlers';
import { columnsWallets } from './columns-definitions';
import { walletsFilters, walletsSelectionMacroCommand } from './wallets-filter-config';

type Props = {
  urlParams: Readonly<Partial<IdentityDetailsUrlParams>>;
  setIsDetailsPageError: React.Dispatch<React.SetStateAction<boolean>>;
};

/** @deprecated */
export const WalletsTab: React.FC<Props> = ({ urlParams, setIsDetailsPageError }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { downloadCsv, isLoading: isExportLoading, error: exportError } = useExport();

  const [getWalletsTrigger, walletsResult] = useLazyGetWalletsQuery();

  const fetchWallets = useCallback(
    (page?: number, filterResult?: FilterResult) =>
      getWalletsTrigger({ page, ownerIdentityId: urlParams.identityId, filterResult }, false),
    [getWalletsTrigger, urlParams.identityId],
  );

  const [walletRows, walletsResourceCount] = useTransformCollectionResponse<WalletResponseModel, WalletRow>(
    walletsResult.data,
    onWalletTransform,
  );

  useEffect(() => {
    fetchWallets();
  }, [fetchWallets]);

  // handle all page error - start
  useEffect(() => {
    setIsDetailsPageError(walletsResult.isError);
  }, [setIsDetailsPageError, walletsResult.isError]);
  // handle all page error - end

  return (
    <CommonLayoutContainer>
      <CommonLayoutContent>
        <CommonGridBox>
          <CustomizedGrid
            columns={columnsWallets}
            headerName={t('walletsPage.gridTitle')}
            filters={walletsFilters}
            selectionMacroCommand={walletsSelectionMacroCommand}
            noResultsTitle={t('walletsPage.noResultsTitle')}
            noResultsSubtitle={t('walletsPage.noResultsSubtitle')}
            containerHeight="100%"
            rows={walletRows}
            rowCount={walletsResourceCount}
            isLoading={walletsResult.isFetching}
            error={walletsResult.error || exportError}
            fetchOtherItems={fetchWallets}
            onGridRowClick={onWalletRowClick(navigate, urlParams)}
            onExportClick={onWalletsExportClick(downloadCsv, urlParams.identityId!)}
            isExportLoading={isExportLoading}
          />
        </CommonGridBox>
      </CommonLayoutContent>
    </CommonLayoutContainer>
  );
};
