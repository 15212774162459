import React from 'react';
import Breadcrumbs, { BreadCrumbsItems } from 'components/shared/Breadcrumbs';
import { CommonLayoutContainer, CommonLayoutContent, CommonLayoutHeader } from 'components/shared/layouts/CommonLayout';
import { CommonErrorLayout } from 'components/shared/layouts/CommonErrorLayout';
import { HeaderBox } from 'components/shared/detail-page/HeaderBox';

type Props = {
  breadCrumbs: BreadCrumbsItems;
};

/** @deprecated */
export const Error: React.FC<Props> = ({ breadCrumbs }) => {
  return (
    <CommonLayoutContainer>
      <CommonLayoutHeader>
        <HeaderBox>
          <Breadcrumbs items={breadCrumbs} />
        </HeaderBox>
      </CommonLayoutHeader>
      <CommonLayoutContent>
        <CommonErrorLayout />
      </CommonLayoutContent>
    </CommonLayoutContainer>
  );
};
